import React from 'react';
import cx from 'classnames';

import * as styles from './Card.module.scss';

const Card = ({
  id,
  Image,
  Icon,
  title,
  subtitle,
  text,
  list,
  simple,
  bigger,
  hover,
  hidden,
  children,
}) => {
  return (
    <div
      id={id}
      className={cx(
        styles.card,
        simple && styles.cardSimple,
        bigger && styles.cardBigger,
        hover && styles.cardHover,
        hidden && styles.cardHidden,
      )}
    >
      {Image && <div className={styles.card__image}>{Image}</div>}
      <div className={styles.card__content}>
        <div className={styles.card__icon}>{<Icon />}</div>

        <div>
          <div className={styles.card__title}>{title}</div>
          {subtitle && <div className={styles.card__subtitle}>{subtitle}</div>}
          <div className={styles.card__text}>{text}</div>
          {list && (
            <ul className={styles.card__list}>
              {list.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Card;
