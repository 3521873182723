import React from 'react';
import { useIntl } from 'react-intl';

import cx from 'classnames';

const FormField = ({ formik, input }) => {
  const intl = useIntl();
  const isInvalid = formik.touched[input.name] && formik.errors[input.name];

  return (
    <div className={cx('input-group', isInvalid && 'input-group--error')}>
      <label htmlFor={input.id} className="form-label">
        {input.label}
        {input.required && ' *'}
      </label>
      {input.type === 'textarea' ? (
        <textarea
          id={input.id}
          className="form-control"
          name={input.name}
          placeholder={input.placeholder}
          {...formik.getFieldProps(input.name)}
        />
      ) : (
        <input
          id={input.id}
          className="form-control"
          type={input.type}
          name={input.name}
          placeholder={input.placeholder}
          {...formik.getFieldProps(input.name)}
        />
      )}

      {isInvalid ? (
        <div className="invalid-feedback">
          {intl.formatMessage({
            id: formik.errors[input.name],
          })}
        </div>
      ) : null}
    </div>
  );
};

export default FormField;
