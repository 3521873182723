import React from 'react';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';

import * as styles from './WhyUs.module.scss';

import Section from '../section/Section';

import { fadeIn, fadeInBottom } from '../../framer-motion/variants';

import exchange from './assets/exchange.svg';
import truck from './assets/truck.svg';
import document from './assets/document.svg';
import worldwide from './assets/worldwide.svg';

const WhyUs = () => {
  const intl = useIntl();

  const features = [
    {
      icon: exchange,
      title: intl.formatMessage({ id: 'why-us.exchange.title' }),
      text: intl.formatMessage({ id: 'why-us.exchange.text' }),
    },
    {
      icon: truck,
      title: intl.formatMessage({ id: 'why-us.truck.title' }),
      text: intl.formatMessage({ id: 'why-us.truck.text' }),
    },
    {
      icon: document,
      title: intl.formatMessage({ id: 'why-us.document.title' }),
      text: intl.formatMessage({ id: 'why-us.document.text' }),
    },
    {
      icon: worldwide,
      title: intl.formatMessage({ id: 'why-us.worldwide.title' }),
      text: intl.formatMessage({ id: 'why-us.worldwide.text' }),
    },
  ];

  return (
    <Section
      id="why-us"
      title={intl.formatMessage({ id: 'why-us.title' })}
      className={styles.whyUs}
    >
      <div className={styles.whyUs__background}>
        <motion.span
          variants={fadeIn}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
        ></motion.span>
        {intl.formatMessage({ id: 'why-us.title' })}
      </div>

      <div className="container">
        <div className="row">
          <div className="col-xl-10 offset-xl-1">
            <div className={styles.features}>
              {features.map(({ icon, title, text }) => (
                <motion.div
                  key={title}
                  variants={fadeInBottom}
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true }}
                  className={styles.features__item}
                >
                  <div className={styles.feature}>
                    <div className={styles.feature__icon}>
                      <img src={icon} alt="" />
                    </div>
                    <div className={styles.feature__title}>{title}</div>
                    <div>{text}</div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default WhyUs;
