// extracted by mini-css-extract-plugin
export var card = "Card-module--card--c07dd";
export var cardBigger = "Card-module--card--bigger--db8a2";
export var cardHidden = "Card-module--card--hidden--48652";
export var cardHover = "Card-module--card--hover--1a3ec";
export var cardSimple = "Card-module--card--simple--3c1ca";
export var card__content = "Card-module--card__content--d8dea";
export var card__contentWrapper = "Card-module--card__contentWrapper--08f90";
export var card__icon = "Card-module--card__icon--cd29d";
export var card__iconWrapper = "Card-module--card__iconWrapper--6df26";
export var card__image = "Card-module--card__image--31dc8";
export var card__list = "Card-module--card__list--7dc6c";
export var card__subtitle = "Card-module--card__subtitle--0975e";
export var card__text = "Card-module--card__text--0c06f";
export var card__title = "Card-module--card__title--689a2";