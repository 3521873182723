import React from 'react';
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';

import cx from 'classnames';

import * as styles from './Services.module.scss';

import Section from '../section/Section';
import Card from '../card/Card';

import { fadeInRight, fadeInBottom } from '../../framer-motion/variants';

import ShipIcon from '../../assets/ship.inline.svg';
import PlaneIcon from '../../assets/plane.inline.svg';
import TruckIcon from '../../assets/truck.inline.svg';
import ClipboardIcon from '../../assets/clipboard.inline.svg';

const ControlButton = ({ Icon, title, active, onClick }) => {
  return (
    <button
      className={cx(styles.control__item, active && styles.control__itemActive)}
      onClick={onClick}
    >
      <Icon />
      {title}
    </button>
  );
};

const Services = ({ activeCard, onControlClick }) => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query ServiceCardsImages {
      allFile(filter: { relativeDirectory: { eq: "service-cards" } }) {
        edges {
          node {
            name
            extension
            dir
            modifiedTime
            childImageSharp {
              gatsbyImageData(width: 870, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  `);

  const imagesList = data.allFile.edges;

  const cards = [
    {
      id: 'ocean-freight',
      icon: ShipIcon,
      title: intl.formatMessage({
        id: 'services.ocean.title',
      }),
      subtitle: intl.formatMessage({
        id: 'services.ocean.subtitle',
      }),
      text: intl.formatMessage({
        id: 'services.ocean.text',
      }),
      list: [
        intl.formatMessage(
          {
            id: 'services.ocean.list.first',
          },
          {
            br: <br />,
            ul: (str) => <ul>{str}</ul>,
            li: (str) => <li>{str}</li>,
          },
        ),
        intl.formatMessage(
          {
            id: 'services.ocean.list.second',
          },
          { br: <br />, b: (chunks) => <b>{chunks}</b> },
        ),
      ],
    },
    {
      id: 'air-freight',
      icon: PlaneIcon,
      title: intl.formatMessage({
        id: 'services.air.title',
      }),
      text: intl.formatMessage({
        id: 'services.air.text',
      }),
      list: [
        intl.formatMessage({
          id: 'services.air.list.first',
        }),
        intl.formatMessage({
          id: 'services.air.list.second',
        }),
        intl.formatMessage({
          id: 'services.air.list.third',
        }),
      ],
    },
    {
      id: 'land-freight',
      icon: TruckIcon,
      title: intl.formatMessage({
        id: 'services.land.title',
      }),
      text: intl.formatMessage({
        id: 'services.land.text',
      }),
      list: [
        intl.formatMessage({
          id: 'services.land.list.first',
        }),
        intl.formatMessage({
          id: 'services.land.list.second',
        }),
      ],
    },
    {
      id: 'ecomm',
      icon: ClipboardIcon,
      title: intl.formatMessage({
        id: 'services.ecomm.title',
      }),
      text: intl.formatMessage({
        id: 'services.ecomm.text',
      }),
      list: [
        intl.formatMessage({
          id: 'services.ecomm.list.first',
        }),
        intl.formatMessage({
          id: 'services.ecomm.list.second',
        }),
        intl.formatMessage({
          id: 'services.ecomm.list.third',
        }),
      ],
    },
  ];

  return (
    <Section
      id="services"
      title={intl.formatMessage({ id: 'services.title' })}
      theme="light"
      className={styles.services}
    >
      <div className="container">
        <motion.div
          variants={fadeInBottom}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          className={styles.quote}
        >
          {intl.formatMessage({ id: 'services.quote' })}
        </motion.div>

        <div id="services-cards" className={cx('row', styles.cardsWrapper)}>
          <motion.div
            variants={fadeInBottom}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            className={cx('col-lg-9', styles.cards)}
          >
            {cards.map(({ id, icon, title, subtitle, text, list }) => {
              const image = getImage(
                imagesList.find((image) => id === image.node.name).node,
              );

              return (
                <Card
                  key={id}
                  id={id}
                  Icon={icon}
                  Image={
                    <GatsbyImage
                      image={image}
                      style={{ height: '100%', width: '100%' }}
                      imgStyle={{ objectFit: 'cover' }}
                      loading="eager"
                      alt=""
                    />
                  }
                  title={title}
                  subtitle={subtitle}
                  text={text}
                  list={list}
                  bigger
                  hidden={activeCard !== id}
                >
                  <Link
                    to="contact-us"
                    smooth={true}
                    duration={500}
                    className={cx('btn btn--white', styles.cards__action)}
                  >
                    {intl.formatMessage({ id: 'services.request' })}
                  </Link>
                </Card>
              );
            })}
          </motion.div>

          <motion.div
            variants={fadeInRight}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            className={cx('col-lg-3', styles.control)}
          >
            <div className={styles.control__inner}>
              {cards.map(({ id, title, icon }) => (
                <ControlButton
                  key={id + title}
                  id={id}
                  Icon={icon}
                  title={title}
                  active={activeCard === id}
                  onClick={() => onControlClick(id)}
                ></ControlButton>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </Section>
  );
};

export default Services;
