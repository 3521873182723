// extracted by mini-css-extract-plugin
export var about = "About-module--about--ef92d";
export var about__footer = "About-module--about__footer--4b67b";
export var about__intro = "About-module--about__intro--05d21";
export var cards = "About-module--cards--72d77";
export var feature = "About-module--feature--7cec8";
export var feature__heading = "About-module--feature__heading--5093e";
export var feature__text = "About-module--feature__text--58225";
export var feature__title = "About-module--feature__title--03ba1";
export var features = "About-module--features--a154a";
export var values = "About-module--values--0d56b";
export var values__background = "About-module--values__background--822f7";
export var values__content = "About-module--values__content--359dc";
export var values__title = "About-module--values__title--e2699";