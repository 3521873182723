import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';

import * as Yup from 'yup';
import cx from 'classnames';

import * as styles from './HeroForm.module.scss';

import { ModalContext } from '../../context/modal-context';

import FormField from '../form-field/FormField';
import { postData } from '../../http/post-data';
import { trackContact } from '../../utils/analytics';

const HeroField = ({ formik, input }) => {
  return (
    <div className={cx(input.full ? 'col-md-12' : 'col-md-4')}>
      <FormField input={input} formik={formik} />
    </div>
  );
};

const HeroForm = () => {
  const intl = useIntl();
  const { openModal } = useContext(ModalContext);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      additional: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().min(2, 'forms.invalid.min2'),
      email: Yup.string().email(
        intl.formatMessage({
          id: 'forms.invalid.email',
        }),
      ),
      phone: Yup.string()
        .min(10, 'forms.invalid.min10')
        .required('forms.required'),
      additional: Yup.string(),
    }),
    onSubmit: async (values, actions) => {
      trackContact();

      actions.setStatus({
        success: intl.formatMessage({
          id: 'forms.sending',
        }),
      });

      postData('https://api.gofreightastic.com/contact', values).then(() => {
        actions.setStatus({
          success: null,
        });

        const callback = () => actions.resetForm();

        openModal({
          title: intl.formatMessage({
            id: 'forms.success.title',
          }),
          body: intl.formatMessage({
            id: 'forms.success.text',
          }),
          callback,
        });
      });
    },
  });

  const inputs = [
    {
      id: 'contact-name',
      type: 'text',
      name: 'name',
      label: intl.formatMessage({
        id: 'contact-us.form.name.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.name.placeholder',
      }),
      required: false,
      full: false,
    },
    {
      id: 'contact-phone',
      type: 'tel',
      name: 'phone',
      label: intl.formatMessage({
        id: 'contact-us.form.phone.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.phone.placeholder',
      }),
      required: true,
      full: false,
    },
    {
      id: 'contact-email',
      type: 'email',
      name: 'email',
      label: intl.formatMessage({
        id: 'contact-us.form.email.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.email.placeholder',
      }),
      required: false,
      full: false,
    },
    {
      id: 'contact-additional',
      type: 'textarea',
      name: 'additional',
      label: intl.formatMessage({
        id: 'contact-us.form.additional.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.additional.placeholder',
      }),
      required: false,
      full: true,
    },
  ];

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <div className={styles.form__title}>
        {intl.formatMessage({ id: 'hero.form.title' })}
      </div>

      <div className="row">
        {inputs.map((input) => (
          <HeroField key={input.name} input={input} formik={formik} />
        ))}
      </div>

      <div className={styles.form__action}>
        <button type="submit" className={cx('btn', styles.form__submit)}>
          {formik.status?.success
            ? formik.status.success
            : intl.formatMessage({
                id: 'hero.form.submit',
              })}
        </button>
      </div>

      <div className={styles.form__caption}>
        {intl.formatMessage({ id: 'hero.form.footer' })}
      </div>
    </form>
  );
};

export default HeroForm;
