import React from 'react';
import { motion } from 'framer-motion';

import cx from 'classnames';

import * as styles from './Section.module.scss';

import { fadeInLeft } from '../../framer-motion/variants';

const Section = ({
  id,
  title,
  theme = 'accent',
  titleTop,
  className,
  children,
}) => {
  return (
    <section
      id={id}
      className={cx(
        styles.section,
        theme === 'accent' && styles.sectionDark,
        theme === 'light' && styles.sectionLight,
        titleTop && styles.sectionTitleTop,
        className,
      )}
    >
      {title && (
        <div className={styles.title}>
          <motion.div
            variants={fadeInLeft}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
          >
            <div className={styles.title__text}>{title}</div>
          </motion.div>
        </div>
      )}

      {children}
    </section>
  );
};

export default Section;
