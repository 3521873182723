import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { ModalContext } from '../../context/modal-context';

import cx from 'classnames';

const Modal = ({ active, title, body, callback }) => {
  const intl = useIntl();
  const { closeModal } = useContext(ModalContext);

  const handleClose = (callback) => {
    closeModal();

    if (callback) {
      callback();
    }
  };

  return (
    <>
      {active && (
        <div
          className="modal-backdrop"
          onClick={() => handleClose(callback)}
          aria-hidden="true"
        />
      )}

      <div
        className={cx('modal', active && 'modal--show')}
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal__dialog">
          <div className="modal__content">
            {title && <div className="modal__title">{title}</div>}
            {body && <div className="modal__body">{body}</div>}

            <div className="modal__action">
              <button className="btn" onClick={() => handleClose(callback)}>
                {intl.formatMessage({
                  id: 'modal.close',
                })}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
