import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';

import * as Yup from 'yup';
import cx from 'classnames';

import * as styles from './ContactUsForm.module.scss';

import { ModalContext } from '../../context/modal-context';
import { postData } from '../../http/post-data';
import { trackContact } from '../../utils/analytics';

import FormField from '../form-field/FormField';

const ContactField = ({ formik, input }) => {
  return (
    <div className={cx(input.full ? 'col-md-12' : 'col-md-6')}>
      <FormField input={input} formik={formik} />
    </div>
  );
};

const ContactUsForm = () => {
  const intl = useIntl();
  const { openModal } = useContext(ModalContext);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      origin: '',
      destination: '',
      commodity: '',
      quantity: '',
      weight: '',
      additional: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'forms.invalid.min2')
        .required('forms.required'),
      email: Yup.string()
        .email(
          intl.formatMessage({
            id: 'forms.invalid.email',
          }),
        )
        .required('forms.required'),
      phone: Yup.string()
        .min(10, 'forms.invalid.min10')
        .required('forms.required'),
      origin: Yup.string().min(2, 'forms.invalid.min2'),
      destination: Yup.string().min(2, 'forms.invalid.min2'),
      commodity: Yup.string().min(2, 'forms.invalid.min2'),
      quantity: Yup.string().min(1, 'forms.invalid.min1'),
      weight: Yup.string().min(2, 'forms.invalid.min2'),
    }),
    onSubmit: (values, actions) => {
      trackContact();

      actions.setStatus({
        success: intl.formatMessage({
          id: 'forms.sending',
        }),
      });

      postData('https://api.gofreightastic.com/contact', values).then(() => {
        actions.setStatus({
          success: null,
        });

        const callback = () => actions.resetForm();

        openModal({
          title: intl.formatMessage({
            id: 'forms.success.title',
          }),
          body: intl.formatMessage({
            id: 'forms.success.text',
          }),
          callback,
        });
      });
    },
  });

  const inputs = [
    {
      id: 'contact-name',
      type: 'text',
      name: 'name',
      label: intl.formatMessage({
        id: 'contact-us.form.name.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.name.placeholder',
      }),
      required: true,
      full: true,
    },
    {
      id: 'contact-email',
      type: 'email',
      name: 'email',
      label: intl.formatMessage({
        id: 'contact-us.form.email.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.email.placeholder',
      }),
      required: true,
      full: true,
    },
    {
      id: 'contact-phone',
      type: 'tel',
      name: 'phone',
      label: intl.formatMessage({
        id: 'contact-us.form.phone.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.phone.placeholder',
      }),
      required: true,
      full: true,
    },
    {
      id: 'contact-origin',
      type: 'text',
      name: 'origin',
      label: intl.formatMessage({
        id: 'contact-us.form.origin.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.origin.placeholder',
      }),
    },
    {
      id: 'contact-destination',
      type: 'text',
      name: 'destination',
      label: intl.formatMessage({
        id: 'contact-us.form.destination.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.destination.placeholder',
      }),
    },
    {
      id: 'contact-commodity',
      type: 'text',
      name: 'commodity',
      label: intl.formatMessage({
        id: 'contact-us.form.commodity.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.commodity.placeholder',
      }),
      full: true,
    },
    {
      id: 'contact-quantity',
      type: 'text',
      name: 'quantity',
      label: intl.formatMessage({
        id: 'contact-us.form.quantity.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.quantity.placeholder',
      }),
    },
    {
      id: 'contact-weight',
      type: 'text',
      name: 'weight',
      label: intl.formatMessage({
        id: 'contact-us.form.weight.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.weight.placeholder',
      }),
    },
    {
      id: 'contact-additional',
      type: 'textarea',
      name: 'additional',
      label: intl.formatMessage({
        id: 'contact-us.form.additional.label',
      }),
      placeholder: intl.formatMessage({
        id: 'contact-us.form.additional.placeholder',
      }),
      full: true,
    },
  ];

  const mainFields = ['name', 'email', 'phone'];

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <h2 className={styles.form__title}>
        {intl.formatMessage({
          id: 'contact-us.form.title',
        })}
      </h2>

      <div className="row">
        {inputs
          .filter((field) => mainFields.includes(field.name))
          .map((input) => (
            <ContactField key={input.name} input={input} formik={formik} />
          ))}

        <div className="col-md-12">
          <hr className={styles.form__separator} />
        </div>

        {inputs
          .filter((field) => !mainFields.includes(field.name))
          .map((input) => (
            <ContactField key={input.name} input={input} formik={formik} />
          ))}
      </div>

      <div className={styles.form__action}>
        <button type="submit" className={cx('btn', styles.form__submit)}>
          {formik.status?.success
            ? formik.status.success
            : intl.formatMessage({
                id: 'contact-us.form.submit',
              })}
        </button>
      </div>

      <footer className={styles.form__caption}>
        {intl.formatMessage({
          id: 'contact-us.form.footer',
        })}
      </footer>
    </form>
  );
};

export default ContactUsForm;
