import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '../styles/style.scss';

import { ModalProvider } from '../context/modal-context';

import Seo from '../components/seo/Seo';
import MobileMenu from '../components/mobile-menu/MobileMenu';
import Hero from '../components/hero/Hero';
import About from '../components/about/About';
import WhyUs from '../components/why-us/WhyUs';
import Services from '../components/services/Services';
import ContactUs from '../components/contact-us/ContactUs';
import Team from '../components/team/Team';

const IndexPage = () => {
  const intl = useIntl();

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeCard, setActiveCard] = useState('ocean-freight');

  const handleToggle = () => {
    document.body.classList.toggle('modal-open');
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCloseMenu = () => {
    document.body.classList.remove('modal-open');
    setMobileMenuOpen(false);
  };

  const handleActiveCard = (id) => {
    setActiveCard(id);
  };

  return (
    <ModalProvider>
      <Seo
        title={intl.formatMessage({
          id: 'meta.title',
        })}
        description={intl.formatMessage({
          id: 'meta.description',
        })}
      />
      <MobileMenu
        isOpen={isMobileMenuOpen}
        toggleMenu={handleToggle}
        handleCloseMenu={handleCloseMenu}
        onControlClick={handleActiveCard}
      />
      <main>
        <Hero onControlClick={handleActiveCard} toggleMenu={handleToggle} />
        <Services activeCard={activeCard} onControlClick={handleActiveCard} />
        <Team />
        <WhyUs />
        <About />
        <ContactUs onControlClick={handleActiveCard} />
      </main>
    </ModalProvider>
  );
};

export default IndexPage;
