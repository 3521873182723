import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';

import { fadeIn } from '../../framer-motion/variants';

import Section from '../section/Section';

import * as styles from './Team.module.scss';

const Team = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query TeamImages {
      allFile(filter: { relativeDirectory: { eq: "team" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                width: 370
                placeholder: DOMINANT_COLOR
                quality: 90
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  const imagesList = data.allFile.edges;

  const team = [
    {
      id: 'adrii',
      name: intl.formatMessage({
        id: 'team.andii',
      }),
      position: intl.formatMessage({
        id: 'team.andii.position',
      }),
    },
    {
      id: 'vova',
      name: intl.formatMessage({
        id: 'team.vova',
      }),
      position: intl.formatMessage({
        id: 'team.vova.position',
      }),
    },
    {
      id: 'lisa',
      name: intl.formatMessage({
        id: 'team.lisa',
      }),
      position: intl.formatMessage({
        id: 'team.lisa.position',
      }),
    },
    {
      id: 'mary',
      name: intl.formatMessage({
        id: 'team.mary',
      }),
      position: intl.formatMessage({
        id: 'team.mary.position',
      }),
    },
    {
      id: 'anastasia',
      name: intl.formatMessage({
        id: 'team.anastasia',
      }),
      position: intl.formatMessage({
        id: 'team.anastasia.position',
      }),
    },
    {
      id: 'julia',
      name: intl.formatMessage({
        id: 'team.julia',
      }),
      position: intl.formatMessage({
        id: 'team.julia.position',
      }),
    },
  ];

  return (
    <Section
      id="team"
      theme="light"
      title={intl.formatMessage({
        id: 'team.title',
      })}
    >
      <motion.span
        variants={fadeIn}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <h2 className={styles.background}>
          {intl.formatMessage({ id: 'team.title' })}
        </h2>
      </motion.span>

      <div className="container">
        <div className={styles.team}>
          {team.map(({ id, name, position }) => {
            const image = getImage(
              imagesList.find((image) => id === image.node.name).node,
            );

            return (
              <div key={id}>
                <div className={styles.image}>
                  <GatsbyImage
                    image={image}
                    style={{ height: '100%', width: '100%' }}
                    imgStyle={{ objectFit: 'cover' }}
                    placeholder="blurred"
                    alt={name}
                  />
                </div>
                <div className={styles.name}>{name}</div>
                <div className={styles.position}>{position}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default Team;
