import React, { createContext, useState } from 'react';
import getScrollSize from '../utils/get-scroll-size';
import Modal from '../components/modal/Modal';

export const ModalContext = createContext({
  openModal: () => {},
  closeModal: () => {},
});

export const ModalProvider = ({ children }) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = (config) => {
    setModalContent(config);

    const bodyPaddingRightOriginal = parseInt(
      window
        .getComputedStyle(document.body, null)
        .getPropertyValue('padding-right'),
    );

    if (
      document.body.clientHeight - document.documentElement.clientHeight >
      0
    ) {
      document.body.style.paddingRight =
        bodyPaddingRightOriginal + getScrollSize() + 'px';
    }
    document.body.classList.add('modal-open');
    setModalOpened(true);
  };

  const closeModal = () => {
    document.body.classList.remove('modal-open');
    document.body.style.paddingRight = '';
    setModalOpened(false);
  };

  const modalProviderValue = {
    openModal,
    closeModal,
  };

  return (
    <ModalContext.Provider value={modalProviderValue}>
      {modalOpened && <Modal active={modalOpened} {...modalContent} />}
      {children}
    </ModalContext.Provider>
  );
};
