import React from 'react';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';

import cx from 'classnames';

import * as styles from './ContactUs.module.scss';

import {
  fadeIn,
  fadeInBottom,
  fadeInRight,
} from '../../framer-motion/variants';

import Section from '../section/Section';
import Header from '../header/Header';
import Social from '../social/Social';
import Footer from '../footer/Footer';
import ContactUsForm from './ContactUsForm';

const ContactUs = ({ onControlClick }) => {
  const intl = useIntl();

  return (
    <Section
      id="contact-us"
      title={intl.formatMessage({
        id: 'contact-us.title',
      })}
      className={styles.contactUs}
    >
      <div className={styles.contactUs__background}>
        <motion.span
          variants={fadeIn}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
        ></motion.span>
        {intl.formatMessage({
          id: 'contact-us.background-title',
        })}
      </div>

      <div className="container">
        <div className={styles.contactUs__top}>
          <div className="row">
            <motion.div
              variants={fadeInBottom}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              className={cx('col-md-10 offset-md-1 col-lg-8 offset-lg-2')}
            >
              <ContactUsForm />
            </motion.div>

            <motion.div
              variants={fadeInRight}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              className={cx(
                'col-lg-2 col-xl-1 offset-xl-1',
                styles.contactUs__socialDesktop,
              )}
            >
              <Social />
            </motion.div>
          </div>
        </div>

        <Footer />

        <div
          className={cx(
            'col-lg-2 col-xl-1 offset-xl-1',
            styles.contactUs__socialMobile,
          )}
        >
          <Social />
        </div>
      </div>

      <div className={styles.contactUs__header}>
        <Header onControlClick={onControlClick} reserve />
      </div>
    </Section>
  );
};

export default ContactUs;
