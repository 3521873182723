import React from 'react';
import { useIntl } from 'react-intl';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import * as styles from './About.module.scss';

import Section from '../section/Section';
import Card from '../card/Card';

import { fadeIn, fadeInBottom } from '../../framer-motion/variants';

import ConsultantIcon from './assets/consultant.inline.svg';
import HandshakeIcon from './assets/handshake.inline.svg';
import LeafIcon from './assets/leaf.inline.svg';
import CogIcon from './assets/cog.inline.svg';
import ShieldIcon from './assets/shield.inline.svg';
import LikeIcon from './assets/like.inline.svg';

const About = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query AboutCardsImages {
      allFile(filter: { relativeDirectory: { eq: "about-cards" } }) {
        edges {
          node {
            name
            extension
            dir
            modifiedTime
            childImageSharp {
              gatsbyImageData(width: 380, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  `);

  const imagesList = data.allFile.edges;

  const cards = [
    {
      id: 'customer',
      icon: ConsultantIcon,
      title: intl.formatMessage({
        id: 'about.cards.customer.title',
      }),
      text: intl.formatMessage({ id: 'about.cards.customer.text' }),
    },
    {
      id: 'collaboration',
      icon: HandshakeIcon,
      title: intl.formatMessage({
        id: 'about.cards.collaboration.title',
      }),
      text: intl.formatMessage({ id: 'about.cards.collaboration.text' }),
    },
    {
      id: 'care',
      icon: LeafIcon,
      title: intl.formatMessage({
        id: 'about.cards.care.title',
      }),
      text: intl.formatMessage({ id: 'about.cards.care.text' }),
    },
    {
      id: 'reliability',
      icon: CogIcon,
      title: intl.formatMessage({
        id: 'about.cards.reliability.title',
      }),
      text: intl.formatMessage({ id: 'about.cards.reliability.text' }),
    },
    {
      id: 'commitment',
      icon: ShieldIcon,
      title: intl.formatMessage({
        id: 'about.cards.commitment.title',
      }),
      text: intl.formatMessage({ id: 'about.cards.commitment.text' }),
    },
    {
      id: 'quality',
      icon: LikeIcon,
      title: intl.formatMessage({
        id: 'about.cards.quality.title',
      }),
      text: intl.formatMessage({ id: 'about.cards.quality.text' }),
    },
  ];

  return (
    <Section
      id="about"
      title={intl.formatMessage({ id: 'about.title' })}
      theme="light"
      titleTop
      className={styles.about}
    >
      <div className="container">
        <motion.div
          variants={fadeInBottom}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          className={styles.about__intro}
        >
          <h2 className="text-center">
            {intl.formatMessage({ id: 'about.heading' })}
          </h2>
          <div className="col-10 offset-1 col-lg-12 offset-lg-0">
            <p>{intl.formatMessage({ id: 'about.text' })}</p>
          </div>
        </motion.div>

        <div className={styles.features}>
          <div className="row">
            <div className="col-10 offset-1 col-md-6 offset-md-0">
              <motion.div
                variants={fadeInBottom}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                className={styles.feature}
              >
                <div className={styles.feature__heading}>
                  {intl.formatMessage({ id: 'about.features.mission.title' })}
                </div>
                <h4 className={styles.feature__title}>
                  {intl.formatMessage({ id: 'about.features.mission.title' })}
                </h4>
                <div className="row">
                  <div className="col-md-10">
                    <div className={styles.feature__text}>
                      {intl.formatMessage({
                        id: 'about.features.mission.text',
                      })}
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="col-10 offset-1 col-md-6 offset-md-0">
              <motion.div
                variants={fadeInBottom}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                className={styles.feature}
              >
                <div className={styles.feature__heading}>
                  {intl.formatMessage({ id: 'about.features.vision.title' })}
                </div>
                <h4 className={styles.feature__title}>
                  {intl.formatMessage({ id: 'about.features.vision.title' })}
                </h4>
                <div className="row">
                  <div className="col-md-10">
                    <div className={styles.feature__text}>
                      {intl.formatMessage({ id: 'about.features.vision.text' })}
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        <div className={styles.values}>
          <div className={styles.values__background}>
            {intl.formatMessage({ id: 'about.values' })}
          </div>
          <div className={styles.values__content}>
            <div className="row">
              <motion.h2
                variants={fadeIn}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                className={styles.values__title}
              >
                {intl.formatMessage({ id: 'about.values' })}
              </motion.h2>

              <motion.div
                variants={fadeInBottom}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                className={styles.cards}
              >
                {cards.map(({ id, icon, title, text }) => {
                  const image = getImage(
                    imagesList.find((image) => id === image.node.name).node,
                  );

                  return (
                    <Card
                      key={title}
                      Image={
                        <GatsbyImage
                          image={image}
                          style={{ height: '100%', width: '100%' }}
                          imgStyle={{ objectFit: 'cover' }}
                          alt=""
                        />
                      }
                      Icon={icon}
                      title={title}
                      text={text}
                      simple
                      hover
                    />
                  );
                })}
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default About;
