import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';

import * as styles from './Hero.module.scss';

import { fadeIn, fadeInBottom } from '../../framer-motion/variants';

import Section from '../section/Section';
import Header from '../header/Header';
import HeroForm from './HeroForm';

const Hero = ({ toggleMenu, onControlClick }) => {
  const intl = useIntl();

  return (
    <Section id="hero" title={intl.formatMessage({ id: 'hero.title' })}>
      <div className={styles.hero}>
        <Header toggleMenu={toggleMenu} onControlClick={onControlClick} />
        <div className={styles.background}>
          <StaticImage src="./assets/hero.jpg" objectFit="cover" alt="" />
        </div>

        <div className={styles.hero__content}>
          <div className="container">
            <div className={`row ${styles.hero__contentWrapper}`}>
              <motion.div
                variants={fadeInBottom}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                className={styles.hero__text}
              >
                <h1 className={styles.hero__title}>
                  {intl.formatMessage({ id: 'hero.h1' })}
                </h1>
                <p className={styles.hero__subtitle}>
                  {intl.formatMessage({ id: 'hero.p1' })}
                </p>
              </motion.div>

              <motion.div
                variants={fadeInBottom}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                className="col-lg-10 offset-lg-1"
              >
                <HeroForm />
              </motion.div>
            </div>
          </div>
        </div>

        <motion.div
          variants={fadeIn}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
        >
          <div className={styles.hero__caption}>
            {intl.formatMessage({ id: 'hero.background' })}
          </div>
        </motion.div>
      </div>
    </Section>
  );
};

export default Hero;
